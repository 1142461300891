import mock from '../mock'
const data = {
  typeEvent: [
    {
      id: '1',
      value: '1',
      label: 'Общее событие'
    },
    {
      id: '2',
      value: '2',
      label: 'Конференция'
    }
  ],
  people: [
    {
      id: '1',
      value: '1',
      label: 'Ситников Г.'
    },
    {
      id: '2',
      value: '2',
      label: 'Тарасов А.'
    },
    {
      id: '3',
      value: '3',
      label: 'Насыров С.'
    }
  ]
}

mock.onGet('/api/select/data').reply(() => {
  return [200, data]
})
