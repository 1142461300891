// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import $api from '@src/http'

export const getAllData = createAsyncThunk('appUsers/getAllData', async () => {
  const response = await $api.get('/user/')
  return response.data
})

export const getData = createAsyncThunk('appUsers/getData', async params => {
  const response = await $api.get('/user/', params)
  console.log(response)
  return {
    params,
    data: response.data.users,
    totalPages: response.data.length
  }

})

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const response = await $api.get('/user/', { id })
  return response.data.user
})
export const editUser = createAsyncThunk('appUsers/editUser', async (arg) => {
  console.log(arg)
  const response = await $api.patch(`/user/${arg.id}/`, arg.data)
  return response
}) 
export const getUserInfo = createAsyncThunk('appUsers/getUserInfo', async id => {
  const responseUser = await $api.get(`/user/${id}/`)
  let responseMember = await $api.get('/member/')
  responseMember = responseMember.data.filter(item => item.user === id)
  console.log(responseMember)
  console.log(responseUser)
  const response = [responseUser.data, ...responseMember]
  console.log(response)
  return response
})
export const addMember = createAsyncThunk('appUsers/addMember', async (member) => {
  const response = await $api.post('/member/', member)
  return response
})
export const editMember = createAsyncThunk('appUsers/editMember', async (arg) => {
  console.log(arg)
  const response = await $api.patch(`/member/${arg.user}/`, arg.member)
  return response
}) 
export const getMember = createAsyncThunk('appUsers/getMember', async (id) => {
  // const response = await $api.get('/member/', id)
  // return response
  console.log(`Данные с гет мембер ${id}`)
})

export const getMembers = createAsyncThunk('appUsers/getMembers', async () => {
  const response = await $api.get('/member/')
  return response
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState, rejectWithValue }) => {
  try {
    const response = await $api.post('/user/', user)
    await dispatch(getData(getState().users.params))
    console.log(user)
    const newUser = await dispatch(getAllData())
    const newUserID = newUser.payload.reduce((acc, curr) => (acc.id > curr.id ? acc : curr))
    if (user.member === 0) { //Создаем нового участника если не пришел его ID с формы
      console.log('создание нового')
      console.log(user.member)
      console.log(typeof (user.member))
      
      const member = {
        first_name: user.first_name,
        middle_name: user.middle_name,
        last_name: user.last_name,
        phonenumber: user.phonenumber,
        telegram: user.telegram,
        organizaiotn: null,
        user: newUserID.id,
        events_author: [],
        events_member: []
      }
      await dispatch(addMember(member))
      console.log(member)
      console.log(user.first_name)
      console.log(newUserID.id)
    } else { //Иначе редактируем существующего
      console.log('Редактирование существующего')
      console.log(user.member)
      console.log(typeof (user.member))
      const member = {
        user: newUserID.id
      }
      console.log(member)
      await dispatch(editMember({user: user.member, member: member})) // eslint-disable-line
    }
    return response 
  } catch (err) {
    if (!err.response) {
      throw err
    }
    return rejectWithValue(err.response.data)
  }

 
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await $api.delete(`/user/${id}`)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})


export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    members: [],
    userInfo: [{email: '', is_superuser: false}, {}],
    loadingInfo: false, 
    isOpenModalEdit: false,
    errorCreating: '',
    selectedUser: null
  },
  reducers: {
    handleModalEdit: (state, action) => {
      state.isOpenModalEdit = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
        console.log('init allData')
        console.log(state.allData)
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(addUser.pending, (state) => {
        state.loadingInfo = false
      })
      .addCase(addUser.fulfilled, (state) => {
        state.loadingInfo = true
      })
      .addCase(addUser.rejected, (state) => {
        state.errorCreating = 'Ошибка создания пользователя'
        state.loadingInfo = true
      })
      .addCase(getMembers.fulfilled, (state, action) => {
        state.members = action.payload
      })
      .addCase(getUserInfo.pending, (state) => {
        state.loadingInfo = false
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.userInfo = action.payload
        state.loadingInfo = true
        console.log(action.payload)
      })
      .addCase(editMember.pending, (state) => {
        state.loadingInfo = false
      })
      .addCase(editMember.fulfilled, (state) => {
        state.loadingInfo = true
      })
      .addCase(editUser.pending, (state) => {
        state.loadingInfo = false
      })
      .addCase(editUser.fulfilled, (state) => {
        state.loadingInfo = true
      })
  }
})
export const { handleModalEdit } = appUsersSlice.actions
export default appUsersSlice.reducer
