import mock from '../mock'

// ** Utils
import { paginateArray } from '../utils'

const data = [
  {
    id: 1,
    start_date: '09/23/2016',
    edit_date: '10/23/2016',
    named: "Маркетинговые коммуникации. Лекция",
    theme: 'Не определена',
    tags: ['Информация', 'Потребность', 'Коммуникация', 'Информация', 'Потребность'],
    people: ['Ситников Г.', 'Тарасов А.'],
    status: 3
  },
  {
    id: 2,
    start_date: '09/23/2016',
    edit_date: '10/23/2016',
    named: "Маркетинговые коммуникации. Лекция",
    theme: 'Не определена',
    tags: ['Информация', 'Потребность', 'Коммуникация', 'Информация', 'Потребность'],
    people: ['Ситников Г.', 'Тарасов А.'],
    status: 3
  }
]

mock.onGet('/api/datatables/initial-data').reply(() => {
  return [200, data]
})

mock.onGet('/api/data-tables/data').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1 } = config
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.filter(
    item =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      item.named.toLowerCase().includes(queryLowered) ||
      item.people.toLowerCase().includes(queryLowered) ||
      item.theme.toLowerCase().includes(queryLowered) ||
      item.start_date.toLowerCase().includes(queryLowered)
  )
  /* eslint-enable  */

  return [
    200,
    {
      allData: data,
      invoices: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})
